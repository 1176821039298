import React from 'react';

function Apps() {
    return (
        <div>
            <img style={{maxHeight: 50, maxWidth: 50, paddingRight: 5}} src={'https://storage.googleapis.com/portfolio-image-repo/apps/emulsion/ic_playstore.png'} alt={""}/>
            <h4>Emulsion - Film Roll Tracker</h4>
            <a href={'https://play.google.com/store/apps/details?id=com.davidseiler.filmexposure'}>Play Store Link</a>
        </div>
    );
}

export default Apps;