import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from './pages/404NotFound';
import Home from './pages/Home/Home';
import Apps from './pages/Apps/Apps';
import Emulsion from './pages/Apps/Emulsion/Emulsion';

const ROUTES = [
    { path: '/', key:'ROOT', exact: true, component: Home }, // Routes with no sub routes look like this
    {
        path: '/apps',        // routes with sub-routes look like this
        key: 'APPS',
        component: RenderRoutes,
        routes: [
            {
                path: '/apps/',
                key: 'CLIENT_INFO',
                exact: true,
                component: Apps
            },
            {
                path: '/apps/Emulsion',
                key: 'EMULSION',
                exact: true,
                component: Emulsion
            },
        ]
    },
];

export default ROUTES;

export function RenderRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
            <Route component={NotFound} />
        </Switch>
    );
}

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    );
}