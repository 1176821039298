import React from 'react';
import ROUTES, { RenderRoutes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss'

function App() {
    return (
        // <ThemeProvider theme={GlobalTheme}>
        <BrowserRouter>
            <RenderRoutes routes={ROUTES} />
        </BrowserRouter>
        // </ThemeProvider>
    );
}

export default App;
