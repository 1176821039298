import {useEffect, useState} from "react";


function useScrollDirection(sensitivity) {
    const [scrollDirection, setScrollDirection] = useState(null)

    useEffect(() => {
        let lastScrollY = window.pageYOffset

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset
            const direction = scrollY > lastScrollY ? "down" : "up"
            if (direction !== scrollDirection && (scrollY - lastScrollY > sensitivity || scrollY - lastScrollY < -sensitivity)) {
                setScrollDirection(direction)
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        }
        window.addEventListener("scroll", updateScrollDirection)
        return () => {
            window.removeEventListener("scroll", updateScrollDirection)
        }
    }, [scrollDirection])
    return scrollDirection;
}

export default useScrollDirection;
