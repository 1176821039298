import React from 'react'
import GithubIcon from "./icons/GithubIcon";
import LinkedInIcon from "./icons/LinkedInIcon";


function Links() {

    return (
        <div className="links">
            <a href={"https://github.com/davidseiler"}>
                <GithubIcon />
            </a>
            <a href={"https://www.linkedin.com/in/david-seiler-a55853222/"}>
                <LinkedInIcon />
            </a>
        </div>

    );
}

export default Links;