import React, {useEffect, useRef, useState} from 'react'
import useScrollDirection from "../hooks/useScrollDirection";
import HamburgerIcon from './icons/HamburgerIcon'
import CloseIcon from "./icons/CloseIcon";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Links from "./Links";


function Nav() {
    const scrollDirection = useScrollDirection(5);
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = (event) => {
        setMenuOpen(!menuOpen)
        if (!menuOpen) {
            document.body.style.overflow = "hidden"
            // the MUI form does not follow z-index rules, so this is a workaround until it is updated
            document.getElementById("dumb-mui-form").style.zIndex = -1;
        } else {
            document.body.style.overflow = "visible"
            document.getElementById("dumb-mui-form").style.zIndex = 1;
        }
    }

    const closeMenu = (event) => {

        console.log("menu closed")
        document.body.style.overflow = "visible"
        document.getElementById("dumb-mui-form").style.zIndex = 1;
        setMenuOpen(false)
    }

    const wrapperRef = useRef();
    useOnClickOutside(wrapperRef, () => menuOpen ? toggleMenu() : null);

    return (
        <header ref={wrapperRef} className={`header ${scrollDirection === "down" ? "hide" : "show"}`}>
            <nav id="nav-header">
                <h1 className="title">
                    <a id="header-name" href="/">David Seiler</a>
                </h1>
                <div className="nav-items">
                    {/*<a href="/#about">About</a>*/}
                    {/*<a href="/#experience">Experience</a>*/}
                    {/*<a href="/#projects">Projects</a>*/}
                    <a href="/#contact">Contact</a>
                </div>
                <div id="home-header" className="menu-button-container">
                    <button id="menu-button" type="image" className="menu-button" aria-controls="menu" onClick={toggleMenu}>
                        {!menuOpen ?
                            <HamburgerIcon className={"hamburger-icon"}/>
                            :
                            <CloseIcon className={"hamburger-icon"}/>
                        }
                    </button>
                </div>
            </nav>
                {menuOpen ?
                    <aside className="burger-menu">
                        <div className="burger-nav" onClick={toggleMenu}>
                            <a href="/#about">About</a>
                            <a href="/#experience">Experience</a>
                            <a href="/#projects">Projects</a>
                            <a href="/#contact">Contact</a>
                        </div>
                        <Links />
                    </aside>

                : null}
        </header>
    );
}

export default Nav;