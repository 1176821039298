import React, { useEffect } from 'react';
import Nav from "../../components/Nav";
import Contact from "../Contact/Contact";
import Projects from "./Projects";
import WorkExperience from "./WorkExperience";
import About from "./About";
import Links from "../../components/Links";

function Home() {

    return (
        <div>
            <Nav/>
            <div id="main-content">
                {/*<About/>*/}
                {/*<WorkExperience/>*/}
                {/*<Projects/>*/}
                <Contact/>
            </div>
            <footer>
                {/*<Links/>*/}
                <p >Created by David Seiler</p>
            </footer>
        </div>
    );
}

export default Home;