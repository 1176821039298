import React, {useState} from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab"
import Emulsion from "./Emulsion/Emulsion";

function Apps() {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} variant={"fullWidth"} >
                    <Tab label="Android" sx={{ border: 1, borderColor: 'divider' }}/>
                    <Tab label="iOS" sx={{ border: 1, borderColor: 'divider' }} />
                </Tabs>
            </Box>
            <TabPanel value={0}>
                    <Emulsion />
            </TabPanel>
            <TabPanel value={1}>
                <h1>Coming soon</h1>
            </TabPanel>
        </TabContext>
    );
}

export default Apps;