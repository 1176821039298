import React, { useState } from 'react';
import { Container, FormControl, Typography, TextField, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";

function Contact() {
    let startData = {
        fname: "",
        lname: "",
        email: "",
        subject: "",
        message: "",
    }
    let startValid = {
        fname: true,
        lname: true,
        email: true,
        message: true,
    }
    const [ formData, setFormData ] = useState(startData);
    const [ validFields, setValidFields ] = useState(startValid);
    const [ fail, setFail ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const submitForm = () => {
        if (!formData.subject) {
            formData.subject = "No Subject"
        }
        setSending(true);
        axios.post("https://api.davidseiler.com/api/contact", formData, {headers: {
                'Access-Control-Allow-Origin': '*',
            }, withCredentials: false,})
            .then( res => {
                if (res.status === 200) {
                    setSuccess(true);
                    setSending(false);
                    setFormData(startData);
                    setValidFields(startValid);
                }
            })
            .catch(() => {
                setFail(true);
                setSending(false);
            });
    }

    const clearForm = () => {

    }

    const handleClose = () => {
        setFail(false);
        setSuccess(false);
    }

    const validateForm = () => {
        let valid = {
            fname: formData.fname.length > 1,
            lname: formData.lname.length > 1,
            email: /^\S+@\S+\.\S+$/.test(formData.email),
            message: formData.message.length > 50,
        }
        setValidFields(valid);

        if ((valid.fname && valid.lname && valid.email && valid.message)) {
            submitForm();
        }
    }

    return (
        <Container sx={{zIndex: 1, padding: 0}} className="section" id="contact">
            <Typography variant={"h4"} style={{margin: 10}}>Contact Me</Typography>
                <FormControl id="dumb-mui-form" style={{zIndex: 1, position: "relative"}} onSubmit={submitForm} fullWidth focused={false}>
                        <Grid container spacing={2} style={{maxWidth:"99.65%", zIndex: "1 !important", position: "relative"}}>
                            <Grid style={{zIndex: 0}} item xs={6}>
                                <TextField
                                    fullWidth
                                    value={formData.fname}
                                    id="outlined-disabled"
                                    label="First Name"
                                    style={{margin: 10, zIndex: "inherit", position: "relative"}}
                                    error={!validFields.fname}
                                    onChange={(e) => setFormData({...formData, fname:e.target.value})}
                                    required
                                />
                            </Grid>
                           <Grid style={{zIndex: "1 !important", position: "relative"}} item xs={6}>
                               <TextField
                                   fullWidth
                                   value={formData.lname}
                                   id="outlined-disabled"
                                   label="Last Name"
                                   style={{margin: 10, marginRight:10, zIndex: 0, position: "relative"}}
                                   error={!validFields.lname}
                                   onChange={(e) => setFormData({...formData, lname:e.target.value})}
                                   required
                               />
                           </Grid>
                        </Grid>
                    <TextField
                        id="outlined-disabled"
                        label="Email"
                        style={{margin: 10, position: "relative"}}
                        value={formData.email}
                        error={!validFields.email}
                        helperText={!validFields.email && "Invalid email"}
                        onChange={(e) => setFormData({...formData, email:e.target.value})}
                        required
                    />
                    <TextField
                        id="outlined-disabled"
                        label="Subject"
                        value={formData.subject}
                        onChange={(e) => setFormData({...formData, subject:e.target.value})}
                        style={{margin: 10, zIndex: 0}}
                    />
                    <TextField
                        id="outlined-disabled"
                        label="Message"
                        minRows={3}
                        multiline
                        value={formData.message}
                        style={{margin: 10, zIndex: 0}}
                        error={!validFields.message}
                        onChange={(e) => setFormData({...formData, message:e.target.value})}
                        helperText={!validFields.message && "Message must be greater than 50 characters"}
                        required
                    />
                    <Button onClick={validateForm} disabled={sending} style={{margin: 10, position: "relative", zIndex: "1 !important"}}>Submit</Button>
                </FormControl>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={fail}
                onClose={handleClose}
                message="Failed to send message"
            />
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={success}
                onClose={handleClose}
                message="Message sent"
            />
        </Container>
    );
}

export default Contact;